import axios, { AxiosError } from "axios";
import { Registration } from "../models/registration";
import { ApiResponse } from "../models/api_response";
import { endpoint } from "./base_url";

export async function registrationPOST(
  registration: Registration
): Promise<ApiResponse | undefined> {
  try {
    const response = await axios.post(
      // `${endpoint}/step2/register`,
      `${endpoint}/step1/register`,
      registration
    );
    return {
      statusCode: response.status,
      responseBody: response.data.content,
    };
  } catch (error) {
    if (error instanceof AxiosError) {
      return {
        statusCode: error.response!.status,
        responseBody: error.response?.data.content,
      };
    }
    console.log(error);
    return undefined;
  }
}
